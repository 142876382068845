import { inject, observable } from 'aurelia-framework';

const moment = require("moment");
import { BasicForm } from "../../resources/elements/BasicForm";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { PatientService } from 'resources/services/PatientService';
import { ModalBodyMap } from "resources/elements/modal-body-map";
import { Modal3dBody } from "resources/elements/modal-3dbody";
import * as environment from "../../../config/environment.json";
import { FhirService } from "../../resources/services/FhirService";
import { UserService } from "../../resources/services/UserService";
import { DialogService } from 'aurelia-dialog';
import {Router} from "aurelia-router";
import { QuestionnaireDialogContainer } from 'resources/elements/questionnaire/questionnaire-dialog-container';
import { QuestionnaireService } from 'resources/services/QuestionnaireService';
import { IFormSetting } from 'resources/classes/IFormSettings';
import { QuestionnaireResponse } from 'resources/classes/FhirModules/QuestionnaireResponse';
import {translations} from "resources/classes/translations";
import { fhirEnums } from 'resources/classes/fhir-enums';
import * as Fhir from "resources/classes/FhirModules/Fhir";
import { PermissionService } from 'resources/services/PermissionService';

@inject(I18N, PatientService, UserService, DialogService, Router, FhirService, PermissionService)
export class PatientCurve {
    @observable dateFrom;
    @observable dateTo;
    @observable dateFromComponents;
    @observable dateToComponents;
    @observable patient;
    patientService: PatientService;
    i18n: I18N;
    userService: UserService;
    dialogService: DialogService;
    router: Router;
    fhirService: FhirService;
    permissionService: PermissionService;
    config;
    iframeWrapper;
    medicationsVisible: boolean;
    encounterId: string = undefined;
    container;
    vitalAxes = 0;
    kurveEnabled = false;
    tokenUpdateListenerRemove;
    iframeUrl = '';
    iframeContainer;
    iframeOrigin;
    iframeListener;
    is3dBody: boolean = false;

    constructor(i18n: I18N, patientService: PatientService, userService: UserService, dialogService: DialogService, router: Router, fhirService: FhirService, permissionService: PermissionService) {
        this.patientService = patientService;
        this.i18n = i18n;
        this.userService = userService;
        this.dialogService = dialogService;
        this.router = router;
        this.fhirService = fhirService;
        this.permissionService = permissionService;

        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        this.is3dBody = Boolean(config.settings?.body3d?.enabled);

        this.iframeListener = (event) => {
            if (event.origin === this.iframeOrigin) {
                const data = event.data.data;
                switch (event.data.name) {
                    case 'action': {
                        if (data === "graphixx") {
                            this.dialogService
                                .open({
                                    viewModel: this.is3dBody ? Modal3dBody : ModalBodyMap,
                                    model: {
                                        patient: this.patient,
                                    },
                                    centerHorizontalOnly: this.is3dBody,
                                })
                                .whenClosed((result) => {
                                    if (!result.wasCancelled) {
                                    }
                                });
                        }
                        break;
                    }
                    case 'openQuestionnaireResponse': {
                        this.router.navigate(`#/encounter/${this.encounterId}/questionnaire/${data.qId}/${data.qName}/${data.qrId}`);
                        break;
                    }
                    case 'openQuestionnaireDialog': {
                        this.openModalAnalyseWindow(data.qId, data.qrId);
                        break;
                    }
                }
            }
        };
    }

    protected async openModalAnalyseWindow(questionnaireId, questionnaireResponseId) {
        if (this.patient.isOffline) return;
        const currentTitle = BasicForm.pageTitle;

        const currentQuestionnaire = await QuestionnaireService.GetQuestionnaireById(questionnaireId);
        const currentResponse = await this.fhirService.get(`QuestionnaireResponse/${questionnaireResponseId}`);
        const currentStatus = translations.translate(currentResponse.status);

        const dialogSettings = {
            grouplist: false,
            response: currentResponse,
            questionnaire: currentQuestionnaire,
            encounter: this.patient.encounter,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: currentStatus,
            saveText: this.i18n.tr("save"),
            abortText: this.i18n.tr("abort"),
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            needsEditButton: true,
            advancedSaveButton: true,
            data: {
                title: currentQuestionnaire.title || currentQuestionnaire.name
            }
        };

        return this.dialogService
            .open({viewModel: QuestionnaireDialogContainer, model: dialogSettings, lock: true})
            .whenClosed(async (result) => {
                BasicForm.pageTitle = currentTitle;

                if (!result.wasCancelled) {
                    await this.fhirService.update(result.output);
                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: null
                    }, this.iframeUrl);
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    async activate(params) {
        this.encounterId = params.id;
    }

    async deactivate() {
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
        document.body.classList.remove("hide-process-arrows");

        this.tokenUpdateListenerRemove();

        window.removeEventListener("message", this.iframeListener);
    }

    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Curve);
        this.patient = await this.patientService.fetch(this.encounterId);

        const loginData = sessionStorage.getItem(environment.sessionName);

        await this.userService.forceRefreshToken();
        this.tokenUpdateListenerRemove = this.userService.addTokenUpdateListener(this.sendNewToken.bind(this));

        const oauth = sessionStorage.getItem(environment.oauth2SessionName);

        this.kurveEnabled = this.config.settings && this.config.settings.iframe && this.config.settings.iframe.enabled;

        const query:any = {
            login: loginData,
            oauth: oauth,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: '',
            practitionerName: '',
        };

        if (this.permissionService.isRolesEnabled) {
            query.roleId = this.permissionService.activeUserRole?.id;
        }

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
            query.practitionerName = this.userService.fullNameOrUsername;
        }

        this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');

        this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
        
        if (this.config) {
            BasicForm.pageTitle = this.i18n.tr(this.config.title);
        }

        this.medicationsVisible = this.config && this.config.settings["showMedications"];
        document.body.classList.add("no-toolbar-window");
        if (UserService.UserRole === "doctor")
            document.body.classList.add("hide-process-arrows");

        if (this.patient && this.patient.encounter && this.patient.encounter.period && this.patient.encounter.period.start && this.patient.encounter.period.end) {
            this.dateFrom = moment(this.patient.encounter.period.start).startOf('day').toDate();
            this.dateTo = moment(this.patient.encounter.period.start).startOf('day').add(6, 'days').toDate();
        } else {
            this.dateFrom = moment().endOf('day').subtract(6, 'days').toDate();
            this.dateTo = moment().endOf('day').toDate();
        }

        this.dateFromComponents = this.dateFrom;
        this.dateToComponents = this.dateTo;
        window.addEventListener("message", this.iframeListener);
        
    }

    sendNewToken() {
        if (this.iframeContainer) {
            try {
                this.iframeContainer.contentWindow.postMessage({
                    name: 'update-token',
                    data: sessionStorage.getItem(environment.oauth2SessionName)
                }, this.iframeUrl);
            } catch (e) {
            }
        }
    }

    dateFromChanged(val) {
        this.dateFromComponents = val;
    }

    dateToChanged(val) {
        this.dateToComponents = val;
    }

    axesChanged(val) {
        this.vitalAxes = val;
    }
}
