import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from "aurelia-dialog";
import { bindable } from "aurelia-framework";
import { RuntimeInfo } from "resources/classes/RuntimeInfo";

@autoinject
export class careFileRangeSelect {
    params;
    @bindable dateStart : Date;
    @bindable dateEnd : Date;

    isValid : boolean = false;
    constructor(private controller : DialogController) {
        
    }

    dpOptionsDateOnly = {
        format: RuntimeInfo.DateFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    abort() {
        this.controller?.cancel();
    }

    submit() {
        this.controller.ok( { from: this.dateStart, to: this.dateEnd });
    }

    activate(params) {
        this.params = params;
        const now = new Date();
        this.dateEnd = new Date();
        this.dateStart = new Date(new Date(now.setMonth(now.getMonth()-1)).setDate(1));
    }
}