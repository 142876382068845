import {bindable} from "aurelia-templating";
import {allergyMain} from "./allergy/allergy-main";
import {ModalCaveAdd} from "../../resources/elements/modal-cave-add";
import {NitTools} from "../../resources/classes/NursitTools";
import {UserService} from "../../resources/services/UserService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {Tools} from "../../resources/classes/FhirModules/Tools";
import ResourceType = fhirEnums.ResourceType;
import {DialogMessages} from "../../resources/services/DialogMessages";
import { ConfigService } from "resources/services/ConfigService";

export class CaveView extends allergyMain {
    @bindable path;
    public static HeaderVisibleUrl : string = 'http://nursit-institute.de/StructureDefinition/cave/display-in-title';
    public static CaveDefitionSystem : string = 'http://nursit-institute.de/StructureDefinition/cave';

    listC : any[] = [];
    listV : any[] = [];
    listE : any[] = [];

    areAllergiesReadonly : boolean = false;

    protected override async attached() {
        await super.attached();
        await ConfigService.LoadConfigOverride(this.patient.ward);
        const allergySettings = ConfigService.GetFormSettings("allergy");
        if (typeof allergySettings?.settings?.readonly === "boolean") {
            this.areAllergiesReadonly = allergySettings.settings.readonly;
        }

        window.setTimeout(() => {
            this.showTab(this.path);
        }, 200);
    }

    showTab(pt : string) {
        this.path = pt;
        const lis = document.querySelectorAll('li[id^="cave_tab_"]');
        for (let i = 0; i < lis.length; i++)
            lis[i].classList.remove('active');

        const ele = document.querySelector(`#cave_tab_${pt.toLowerCase()}`);
        ele?.classList.add('active');

        return false;
    }

    addItem() {
        const cat = this.i18n.tr('cave_' + this.path);
        /* Dialogs.showModal({
            content: ModalCaveAdd,
            showCloseButton: true,
            title: this.i18n.tr("cave_create", { category: cat })
        }); */

        this.dialogService.open({
            viewModel: ModalCaveAdd,
            centerHorizontalOnly: true,
            position: DialogMessages.vCenterWithBodyObserver,
            model: {
                caption:  this.i18n.tr("cave_create", { category: cat }),
                title: '',
                text: '',
                targetDate: new Date()
            }
        })
            .whenClosed(e => {
                if (e.wasCancelled)
                    return;

                this.createObservation(e.output)
                    .catch(ex => {
                        console.warn(ex);
                    });
            });
    }

    async createObservation(caveAdd : ModalCaveAdd) {
        let obs = {
            id: NitTools.Uid(),
            resourceType: ResourceType.observation,
            text: {
                status: 'generated',
                div: `<div xmlns="http://www.w3.org/1999/xhtml"><div><b>${Tools.EncodeHtml(caveAdd.title)}</b></div><div>${Tools.EncodeHtml(caveAdd.text)}</div></div>`
            },
            code: {
                coding: [
                    {
                        system: CaveView.CaveDefitionSystem,
                        code: 'caveEntry'
                    }
                ]
            },
            category: [
                {
                    coding: [
                        {
                            system: 'http://nursit-institute.de/StructureDefinition/cave',
                            code: this.path,
                            display: this.i18n.tr('cave_' + this.path)
                        }
                    ],
                    text: this.i18n.tr(`CAVE ${this.i18n.tr('cave_' + this.path)}`)
                }
            ],
            performer: [
                {
                    reference: `Practitioner/${UserService.Practitioner?.id}`,
                    display: UserService.UserName
                }
            ],
            note: [
                {
                    text: Tools.EncodeHtml(caveAdd.text),
                    authorReference: {
                        reference: `Practitioner/${UserService.Practitioner?.id}`,
                        display: UserService.UserName
                    }
                }
            ],
            effectiveDateTime: (caveAdd.targetDate||new Date())?.toJSON(),
            valueString: Tools.EncodeHtml(caveAdd.title),
            subject: {
                reference: `Patient/${this.patient.id}`
            },
            status: "registered"
        };

        if (caveAdd.displayInTitle) {
            obs.code.coding.push({
                system: CaveView.HeaderVisibleUrl,
                code: 'visible-in-header'
            })
        }
        
        await this.fhirService.update(obs, false);
        switch (this.path) {
            case 'C':
                this.listC.push(CaveView.CaveItemFromObservation(obs));
                this.patient.contraindicationsCount = this.listC.length;
                break;
                case 'V':
                    this.listV.push(CaveView.CaveItemFromObservation(obs));
                    this.patient.valuesCount = this.listV.length;
                    break;
                    case 'E':
                        this.listE.push(CaveView.CaveItemFromObservation(obs));
                        this.patient.equipmentCount = this.listE.length;
                        break;
        }
    }

    public static GetCategory(obs) {
        let result : string = undefined;
        for (const cat of obs.category.filter(o=>NitTools.IsArray(o.coding))) {
            const coding = cat.coding.find(o=>o.system?.endsWith('/cave') && o.code);
            if (coding) {
                result = coding.code;
                break;
            }
        }

        return result;
    }

     public static CaveItemFromObservation(obs) {
        let txt : string;
        if (obs.note) {
            txt = obs.note.filter(o=>o.text).map(o=>o.text).join('<br />');
        }

        let title : string = obs.valueString||'Ohne Titel';
        let when = new Date(obs.effectiveDateTime);
        let author = obs.performer.filter(p=>p.display).map(o=>o.display).join(', ');

        let displayInTitle = false;
        if (NitTools.IsArray(obs.code?.coding)) {
            displayInTitle = typeof obs.code.coding.find(o=>o.code === 'visible-in-header') != 'undefined';
        }

        const result =  {
            id: obs.id,
            path: this.GetCategory(obs),
            text: txt,
            title: title,
            when: when,
            author: author,
            observation: obs,
            isLoading : false,
            isDeleted : false,
            displayInTitle : displayInTitle
        };

        // if (ConfigService.Debug) console.warn(result);
        return result;
    }

    async loadCVE() {
        const observations = await this.fhirService.fetch(`Observation?patient=${this.patient.id}&code=caveEntry`);

        for (const obs of observations) {
            const type = CaveView.GetCategory(obs);

            if (type) {
                let item = CaveView.CaveItemFromObservation(obs);
                switch (type) {
                    case 'C':
                        this.listC.push(item);
                        break;
                    case 'V':
                        this.listV.push(item);
                        break;
                    case 'E':
                        this.listE.push(item);
                        break;
                }
            }
        }
    }

    close() {
        this.dialogService.closeAll();
    }

    protected override async activate(params) {
        this.path = params.path||'C';
        if (params.patient)
            this.patient = params.patient;

        await super.activate(params);
        await this.loadCVE();
        this.showTab(this.path);
    }
}
